<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="cons">
        <div class="tpcc-wd">
          <div class="custom-block">我上传的图片</div>
          <div class="tpcc-wd-img">
            <el-image
              style="width: 300px; height: 300px; border: 1px solid #ccc"
              :fit="'contain'"
              :preview-src-list="srcList"
              :src="imgobj.sourcePictureUrl"
            ></el-image>
          </div>
        </div>
        <div class="tpcc-xs">
          <div class="custom-block">最相似图片</div>
          <div class="tpcc-xs-img">
            <el-image
              v-for="(item,key) in imgobj.checkResult"
              :key="key"
              style="width: 280px; height: 280px; border: 1px solid #ccc"
              :fit="'contain'"
              :preview-src-list="ressrcList"
              :initial-index="key"
              :src="item.url"
            ></el-image>
          </div>
        </div>
        <div class="tpcc-res">
           <div class="tpcc-res-val">
            最高相似比：{{xsbmax}}
          </div>
          <div class="tpcc-res-tips">
            <el-tag class="ml-2" type="info"  size="large">注：查重结果仅供参考，请根据查重目的自行确定如何使用查重结果，如是否修改等。</el-tag>
          </div>
        </div>
        <div class="tpcc-cz">
          <el-button type="primary" @click="clicksc">继续上传图片</el-button>
          <el-button type="success" @click="clicklist">返回列表</el-button>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

// 返回的结果
let imgobj=reactive({})
// 预览
let srcList=reactive([])
let ressrcList=reactive([])
// 最高相似比
let xsbmax=ref('0')

// 获取结果
const getresult = () => {
  get(
    "/pictureCheck/getResult/" + router.currentRoute.value.query.id
  ).then((res) => {
    console.log(res);
    Object.assign(imgobj,res.result)
    srcList.push(res.result.sourcePictureUrl)
    res.result.checkResult.forEach(e=>{
      ressrcList.push(e.url)
    })
    xsbmax.value=((res.result.checkResult[0].score * 10)*(res.result.checkResult[0].score * 10)).toFixed(2)+ '%'
  });
};
getresult();

// 跳转
const clicksc=()=>{
  router.push({
    path: "/tpcc/sbu",
  });
}
const clicklist=()=>{
  router.push({
    path: "/tpcc/list",
  });
}
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.custom-block {
  padding: 8px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 4px solid #409eff;
  margin: 20px 0 20px 0;
}
.tpcc-xs-img{
  display: flex;
  justify-content: space-between;
}
.tpcc-res-tips{
  margin:0 0 20px 0;
}
.tpcc-res-val{
  padding: 20px 0;
  color: #f00;
  font-weight: bold;
}
</style>
